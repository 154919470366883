import api from "../api";
import PageableTable from "./PagebleTable/PageableTable";
import defaultUserIcon from "../icons/user-default.svg";
import utils from "../utils/utils";
import {useTranslation} from "react-i18next";

function Top() {
    const {t} = useTranslation()

    const fetcher = (page) => {
        return api.top(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.map((fields) => {
                    return {
                        place: '#' + fields.place,
                        user: (
                            <p className="text-truncate" style={{maxWidth: '160px'}}>
                                <img height="20" className="mx-2"
                                     src={fields.photo_url === '' ? defaultUserIcon : fields.photo_url}
                                     alt={fields.name}/>
                                {fields.name}
                            </p>
                        ),
                        power: utils.nanoToTON(fields.profit),
                    }
                })
                return {data, total: data.length}
            }
        })
    }

    return (
        <div className="row">
            <PageableTable fetcher={fetcher} columns={['', t('Name'), t('Profit')]}/>
        </div>
    );
}

export default Top;