import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import {toast} from "react-toastify";
import {CopyToClipboard} from "react-copy-to-clipboard";
import api from "../../api";
import utils from "../../utils/utils";
import PageableTable from "../PagebleTable/PageableTable";
import classes from "./Deposits.module.css";
import {useTranslation} from "react-i18next";
import {TonConnectButton, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import InputNumber from "./InputNumber";
import {beginCell} from "@ton/core";

function Deposits() {
    const {t} = useTranslation()
    const depositAddress = process.env.REACT_APP_DEPOSIT_ADDRESS
    const {user} = useContext(UserContext)
    const [depositAmount, setDepositAmount] = useState(1)
    const [tonConnectUI, setOptions] = useTonConnectUI()
    const wallet = useTonWallet()

    useEffect(() => {}, [wallet]);

    const fetcher = (page) => {
        return api.deposits(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.items.map((fields) => {
                    return {
                        tx_link: <a href={utils.makeTxLink(fields.tx_hash)} target="_blank" rel="noreferrer">open</a>,
                        amount: <>{utils.nanoToTON(fields.amount)} TON</>,
                        created_at: utils.toDateTime(fields.created_at),
                    }
                })
                return {data, total: response.data.total}
            }
        })
    }

    const payment = () => {
        const body = beginCell()
            .storeUint(0, 32)
            .storeStringTail(user.id.toString())
            .endCell();
        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 60,
            messages: [
                {
                    address: depositAddress,
                    amount: utils.tonToNANO(depositAmount).toString(),
                    payload: body.toBoc().toString("base64")
                }
            ]
        }
        tonConnectUI.sendTransaction(transaction).then(resp => {
            toast.info(t('Tx_was_sent'))
        }).catch(e => {
            toast.error('Something went wrong')
        })
    }

    return (
        <div className="container">
            <h4 className="text-center">{t('Make_deposit')}</h4>

            <div className="text-block p-4">
                <p className="text-center">{t('Your_wallet')}</p>
                <div className="d-flex justify-content-center">
                    <TonConnectButton/>
                </div>
                {
                    wallet &&
                    <>
                        <div className="row my-3 mx-2">
                            <InputNumber value={depositAmount} setValue={setDepositAmount}/>
                        </div>
                        <div className="col-12 text-center">
                            <button className={`${classes.btnDeposit} btn`} onClick={payment}>
                                {t('Deposit')}
                            </button>
                        </div>
                    </>
                }
            </div>


            <div className="text-block p-4">
            <p className="text-center">{t('Manual_deposit')}</p>
                <div className="info-block">
                    {t('Deposit_description')}
                </div>
                <div className="py-2">
                    <div>{t('Deposit_address')}: (click to copy)</div>
                    <CopyToClipboard text={depositAddress} onCopy={() => toast('Copied')}>
                        <button className="btn btn-dark"><span
                            className={classes.depositAddress}>{depositAddress}</span></button>
                    </CopyToClipboard>
                </div>
                <div className="py-2">
                    <div>{t('Comment')}:</div>
                    <CopyToClipboard text={user.id} onCopy={() => toast('Copied')}>
                        <button className="btn btn-dark">{user.id}</button>
                    </CopyToClipboard>
                </div>
            </div>
            <div className="row p-2">
                <h4 className="text-center">{t('History')}</h4>
                <PageableTable fetcher={fetcher} columns={['Tx', t('Amount'), t('Date')]}/>
            </div>
        </div>
    );
}

export default Deposits;