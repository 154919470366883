import PageableTable from "./PagebleTable/PageableTable";
import api from "../api";
import utils from "../utils/utils";
import {toast} from "react-toastify";
import {useContext, useState} from "react";
import {UserContext} from "../App";
import {useTranslation} from "react-i18next";
import {TonConnectButton, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import InputNumber from "./Deposits/InputNumber";
import classes from "./Deposits/Deposits.module.css";
import {beginCell} from "@ton/core";
import {TonClient} from "@ton/ton";

function Withdrawals() {
    const {user} = useContext(UserContext)
    const {t} = useTranslation()
    const [amount, setAmount] = useState(1)
    const [rewardsReloader, setRewardsReloader] = useState(0)

    const [tonConnectUI, setOptions] = useTonConnectUI()
    const wallet = useTonWallet()

    const client = new TonClient({
        endpoint: 'https://toncenter.com/api/v2/jsonRPC',
    })

    const depositAddress = process.env.REACT_APP_DEPOSIT_ADDRESS

    const getStatus = (statusCode) => {
        return statusCode === 1 ? 'paid' : 'pending'
    }
    const fetcher = (page) => {
        return api.withdrawals(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.items.map((fields) => {
                    return {
                        tx_link: <a href={utils.makeTxLink(fields.tx_hash)} target="_blank" rel="noreferrer">open</a>,
                        amount: <> {utils.nanoToTON(fields.amount)} TON</>,
                        status: getStatus(fields.status),
                        created_at: utils.toDateTime(fields.created_at),
                    }
                })
                return {data, total: response.data.total}
            }
        })
    }

    const payment = async () => {
        const balance = await client.getBalance(wallet.account.address)
        let bestValue = utils.tonToNANO(amount)
        switch (true) {
            case balance >= 1e12:
                bestValue = 1e12
                break
            case balance >= 1e11:
                bestValue = 1e11
                break
            case balance >= 5e10:
                bestValue = 5e10
                break
            case balance > bestValue:
                break
            case bestValue === 0:
                toast.error('Something went wrong')
                break
            default:
                bestValue = Number(balance.toString()) - parseInt(Number(balance.toString()) / 10)
                break
        }
        const body = beginCell()
            .storeUint(0, 32)
            .storeStringTail('Withdrawal #' + user.id.toString())
            .endCell();
        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 60,
            messages: [
                {
                    address: depositAddress,
                    amount: bestValue.toString(),
                    payload: body.toBoc().toString("base64")
                }
            ]
        }
        tonConnectUI.sendTransaction(transaction).then(resp => {
            toast.info(t('Tx_was_sent'))
        }).catch(e => {
            toast.error('Something went wrong')
        })
    }

    return (
        <div className="container">
            <h4 className="text-center">{t('Make_withdrawal')}</h4>
            <div className="text-block p-4">
                <div className="d-flex justify-content-center">
                    <TonConnectButton/>
                </div>
                {
                    wallet &&
                    <>
                        <div className="row my-3 mx-2">
                            <InputNumber value={amount} setValue={setAmount}/>
                        </div>
                        <div className="col-12 text-center">
                            <button className={`${classes.btnDeposit} btn`} onClick={() => payment()}>
                                {t('Withdraw')}
                            </button>
                        </div>
                    </>
                }
            </div>
            <div className="row">
                <h4 className="text-center">{t('History')}</h4>
                <PageableTable key={rewardsReloader} fetcher={fetcher}
                               columns={['Tx', t('Amount'), t('Status'), t('Date')]}/>
            </div>
        </div>

    );
}

export default Withdrawals;