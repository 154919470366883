import portableGeneratorImg from './images/portable_generator.png'
import dieselGeneratorImg from './images/diesel_generator.png'
import solarPanelImg from './images/solar_panel.png'
import windTurbineImg from './images/wind_turbine.png'
import biomassPowerPlantImg from './images/biomass_power_plant.png'
import coalPowerPlantImg from './images/coal_power_plant.png'
import gasPowerPlantImg from './images/gas_power_plant.png'
import hydroPowerPlantImg from './images/hydro_power_plant.png'
import nuclearPowerPlantImg from './images/nuclear_power_plant.png'
import goldenTurbineImg from './images/golden_turbine.png'
import diamondIcon from '../../icons/diamond.svg'
import goldenPanelImg from './images/golden_panel.png'
import goldenBioImg from './images/golden_bio.png'
import goldenGasImg from './images/golden_gas.png'
import classes from "./Generators.module.css";
import utils from "../../utils/utils";
import api from "../../api";
import {toast} from "react-toastify";
import {useContext, useEffect, useState} from "react";
import Loader from "../Loader";
import {UserContext} from "../../App";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import Timer from "../Timer";
import RandomGenerator from "./RandomGenerator";

function Generators() {
    const {t} = useTranslation()
    const [generators, setGenerators] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const {reload} = useContext(UserContext);

    const fetchGenerators = () => {
        api.generators().then((response) => {
            if (response.status === 200) {
                const result = response.data
                setGenerators(result)
            }
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchGenerators()
    }, [])

    if (!loaded) {
        return <Loader/>
    }

    const connect = (id) => {
        api.connectGenerator(id).then((response) => {
            if (response.status === 200) {
                toast.success(t('Connected'))
                reload(moment.now())
                fetchGenerators()
            }
        })
    }

    return (
        <div className="row">
            <h6 className="text-center py-2">{t('Generators_phrase')}</h6>
            <RandomGenerator fetchGenerators={fetchGenerators}/>
            {
                generators.map((generator, index) => {
                    const available = generator.until === undefined || moment.unix(generator.until).isAfter(moment.now())
                    if (!available && generator.connected === 0) {
                        return undefined
                    }
                    return (
                        <div className="col-6" key={index}>
                            <div
                                className={`${classes.product} ${generator.until !== undefined ? classes.specialCard : ''}`}>
                                <img height="80" width="80" src={getImgByGeneratorID(generator.id)}
                                     alt={generator.title}/>
                                <p className={classes.productTitle}>{t('generator_' + generator.id)}
                                    {
                                        generator.until !== undefined && moment.unix(generator.until).isAfter(moment.now()) &&
                                        <>
                                            <br/><span className={classes.timer}>
                                        <Timer timeout={generator.until} handleTimeout={() => fetchGenerators()}/>
                                        </span>
                                        </>
                                    }
                                </p>
                                <div>+<strong>{utils.nanoToTON(generator.profit)}</strong> {t('Ton_per_h')}</div>
                                <div>{t('Monthly')}: <strong>{utils.nanoToTON(generator.profit * 24 * 30)}</strong> TON</div>
                                {generator.required && (<div>required: {generator.required}</div>)}
                                <div className="py-2">
                                    {(() => {
                                        if (generator.connected === 0 && generator.required_friends > 0) {
                                            return (
                                                <span
                                                    className={classes.requiredFriends}>Required friends: {generator.required_friends}</span>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    {t('Connected')}: <strong>{generator.connected}</strong>
                                                </>
                                            )
                                        }
                                    })()}
                                </div>
                                <button className="btn " disabled={!available} onClick={() => {
                                    connect(generator.id)
                                }}>
                                    <img src={diamondIcon} className={classes.connectImg} alt="price"/>
                                    {utils.nanoToTON(generator.price)} TON
                                </button>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}


function getImgByGeneratorID(generatorID) {
    switch (generatorID) {
        case 1:
            return portableGeneratorImg
        case 2:
            return dieselGeneratorImg
        case 3:
            return solarPanelImg
        case 4:
            return windTurbineImg
        case 5:
            return biomassPowerPlantImg
        case 6:
            return coalPowerPlantImg
        case 7:
            return gasPowerPlantImg
        case 8:
            return hydroPowerPlantImg
        case 9:
            return nuclearPowerPlantImg
        case 10:
            return goldenTurbineImg
        case 11:
            return goldenPanelImg
        case 12:
            return goldenBioImg
        case 13:
            return goldenGasImg
        default:
            return portableGeneratorImg
    }
}


export default Generators