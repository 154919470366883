
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    Ton_per_h: 'TON/H',
                    Balance: 'Balance',
                    deposit: 'deposit',
                    Deposit: 'Deposit',
                    Generators: "Generators",
                    Tasks: 'Tasks',
                    Friends: 'Friends',
                    Top: 'Top',
                    Payments: 'Payments',
                    Generators_phrase: 'Generator profitability is purchased for the entire time, without restrictions',
                    generator_1: 'Portable generator',
                    generator_2: 'Diesel generator',
                    generator_3: 'Solar panel',
                    generator_4: 'Wind turbine',
                    generator_5: 'Biomass power plant',
                    generator_6: 'Coal power plant',
                    generator_7: 'Gas power plant',
                    generator_8: 'Hydro power plant',
                    generator_9: 'Nuclear power plant',
                    generator_10: 'Golden turbine',
                    generator_11: 'Golden Panel',
                    generator_12: 'Golden bio Plant',
                    generator_13: 'Golden gas plant',
                    Connected: 'Connected',
                    connect: 'connect',
                    Claim: 'Claim',
                    Share: 'Share',
                    Copy: 'Copy',
                    Name: 'Name',
                    Power: 'Power',
                    Referrals: 'Referrals',
                    Your_ton_address: 'Your TON address',
                    Amount: 'Amount',
                    Fee: 'Fee',
                    Withdraw: 'Withdraw',
                    History: 'History',
                    Status: 'Status',
                    Date: 'Date',
                    Time_to_next_claim: 'Time to next claim',
                    Claim_reward: 'Claim free {{amount}} TON',
                    Claim_lucky_ticket: 'Claim a Lucky Ticket',
                    Input: 'Input',
                    Claim_secret_code_title: 'Input secret code to get {{amount}} TON',
                    Subscribe_tg_task_title: 'Subscribe to our TG channel to get {{amount}} Lucky Tickets',
                    Place: 'Place',
                    Profit: 'Profit',
                    List_of_rewards: 'List of rewards',
                    Lucky_wheel: 'Lucky Wheel',
                    SPIN: 'SPIN',
                    How_to_get_lucky_ticket: 'To get a lucky ticket you need to done daily task or invite friend',
                    Jackpot: 'Jackpot',
                    No_data_yet: 'No data yet',
                    Invite_description: 'Invite your friends to get lucky tickets. Also you can earn 10% for each friends purchase and 5% from 2-lvl referrals',
                    Invite_text: 'Open the Tonergy and claim your TON rewards !',
                    Withdrawal_description: 'Make sure that you specify your TON address, otherwise you will lose your assets. Minimal amount is 1 TON. You can have only one pending withdrawal',
                    Make_withdrawal: 'Make Withdrawal',
                    Deposit_description: 'Make sure that you copy the right address and specify a comment when make a deposit, otherwise you will lose your assets',
                    Make_deposit: 'Make deposit',
                    Deposit_address: 'Deposit address',
                    Comment: 'Comment',
                    Find_code: 'Find the code',
                    Find_code_in_chan: 'Find the code in our TG channel',
                    Airdrop_text: 'Your airdrop depends on your power',
                    Soon: 'Soon',
                    Tx_was_sent: 'Tx was sent',
                    Manual_deposit: 'Manual deposit',
                    Your_wallet: 'Your wallet',
                    Rewards: 'Rewards',
                    Monthly: 'Monthly',
                    Random_generator: 'Random Generator',

                    Not_enough_tickets: 'Not enough lucky tickets',
                    Something_went_wrong: 'Something went wrong'
                },
            },
            ru: {
                translation: {
                    Ton_per_h: 'TON/Ч',
                    Balance: 'Баланс',
                    deposit: 'депозит',
                    Generators: 'Генерация',
                    Tasks: 'Задачи',
                    Friends: 'Друзья',
                    Top: 'Топ',
                    Payments: 'Выплаты',
                    Generators_phrase: 'Доходность генератора покупается на все время, без ограничений',
                    generator_1: 'Портативный генератор',
                    generator_2: 'Дизельный генератор',
                    generator_3: 'Солнечная панель',
                    generator_4: 'Ветровая станция',
                    generator_5: 'Био электростанция',
                    generator_6: 'Угольная электростанция',
                    generator_7: 'Газовая электростанция',
                    generator_8: 'Гидро электростанция',
                    generator_9: 'Атомная электростанция',
                    generator_10: 'Золотая турбина',
                    generator_11: 'Золотая панель',
                    generator_12: 'Золотая био станция',
                    generator_13: 'Золотая газовая',
                    Connected: 'Подключено',
                    connect: 'подключить',
                    Claim: 'Забрать',
                    Share: 'Поделиться',
                    Copy: 'Скопировать',
                    Name: 'Имя',
                    Power: 'Мощность',
                    Referrals: 'Рефералы',
                    Your_ton_address: 'Твой TON адрес',
                    Amount: 'Количество',
                    Fee: 'Комиссия',
                    Withdraw: 'Вывести',
                    History: 'История',
                    Status: 'Статус',
                    Date: 'Дата',
                    Time_to_next_claim: 'Будет доступно через',
                    Claim_reward: "Получить {{amount}} TON",
                    Claim_lucky_ticket: "Получить билет удачи",
                    Input: 'Ввести',
                    Claim_secret_code_title: 'Введите секретный код что бы получить {{amount}} TON',
                    Subscribe_tg_task_title: 'Подпишись на канал что бы получить {{amount}} Билета удачи',
                    Place: 'Место',
                    Profit: 'Доход',
                    List_of_rewards: 'Список наград',
                    Lucky_wheel: 'Колесо Удачи',
                    SPIN: 'Крутить',
                    How_to_get_lucky_ticket: "Что бы получить билет удачи нужно выполнить ежедневное задание либо позвать друга",
                    Jackpot: 'Джекпот',
                    No_data_yet: 'Пусто',
                    Invite_description: 'Приглашай друзей чтобы получать билет удачи. Также вы будете получать 10% с каждой покупку вашего друга и 5% с его рефералов',
                    Invite_text: 'Заходи в Tonergy и забирай свои TON !',
                    Withdrawal_description: 'Пожалуйста убедитесь что вы указали свой адрес кошелька в блокчейне TON (telegram) иначе вы потеряете свои деньги',
                    Make_withdrawal: 'Запросить вывод',
                    Deposit_description: 'Обязательно нужно указать комментарий при переводе иначе средства не будут зачислены',
                    Make_deposit: 'Депозит',
                    Deposit_address: 'Депозитный адрес',
                    Comment: 'Комментарий',
                    Find_code: 'Найти код',
                    Find_code_in_chan: 'Найди секретный код в нашем ТГ канале',
                    Airdrop_text: 'Твой airdrop зависит от дохода в игре',
                    Soon: 'Скоро',
                    Tx_was_sent: 'Транзакция отправлена в сеть',
                    Manual_deposit: 'Ручной депозит',
                    Your_wallet: 'Ваш кошелёк',
                    Rewards: 'Награды',
                    Monthly: 'В месяц',
                    Random_generator: 'Случайный Генератор',

                    Not_enough_tickets: 'Недостаточно билетова удачи',
                    Something_went_wrong: 'Что то пошло не так'
                },
            },
            es: {
                translation: {
                    Ton_per_h: 'TON/H',
                    Balance: 'Balance',
                    deposit: 'Depósito',
                    Generators: "Generadores",
                    Tasks: 'Tareas',
                    Friends: 'Amigos',
                    Top: 'Top',
                    Payments: 'Pagos',
                    Generators_phrase: 'La rentabilidad del generador se compra para todo el tiempo, sin restricciones',
                    generator_1: 'Generador portátil',
                    generator_2: 'Generador diésel',
                    generator_3: 'Panel solar',
                    generator_4: 'Estación de viento',
                    generator_5: 'Planta de energía bioeléctrica',
                    generator_6: 'Central eléctrica de carbón',
                    generator_7: 'Central eléctrica de gas',
                    generator_8: 'Central hidroeléctrica',
                    generator_9: 'Planta de energía nuclear',
                    generator_10: 'Turbina dorada',
                    generator_11: 'Panel dorado',
                    generator_12: 'Planta Bio Dorada',
                    generator_13: 'Planta de gas dorado',
                    Connected: 'Conectado',
                    connect: 'Conectar',
                    Claim: 'Afirmar',
                    Share: 'Compartir',
                    Copy: 'Copiar',
                    Name: 'Nombre',
                    Power: 'Ingreso',
                    Referrals: 'Referencias',
                    Your_ton_address: 'Tu dirección TON',
                    Amount: 'Número',
                    Fee: 'Comisión',
                    Withdraw: 'Sacar',
                    History: 'Historia',
                    Status: 'Estado',
                    Date: 'Fecha',
                    Time_to_next_claim: 'Estará disponible a través de',
                    Claim_reward: 'Conseguir {{amount}} TON',
                    Claim_lucky_ticket: 'Consigue la rueda de la fortuna',
                    Input: 'Ingresar',
                    Claim_secret_code_title: 'Introduce el código secreto para obtener {{amount}} TON',
                    Subscribe_tg_task_title: 'Suscríbete al canal TG para recibir {{amount}} Rueda de la fortuna',
                    Place: 'Lugar',
                    Profit: 'Ingreso',
                    List_of_rewards: 'Lista de premios',
                    Lucky_wheel: 'Rueda de la fortuna',
                    SPIN: 'Girar',
                    How_to_get_lucky_ticket: 'Para obtener un boleto, debes completar una tarea diaria o invitar a un amigo.',
                    Jackpot: 'Bote',
                    No_data_yet: 'Vacío',
                    Invite_description: 'Invita a tus amigos a conseguir la Rueda de la Fortuna. También ganarás el 10% de la compra de cada amigo y el 5% de sus referencias.',
                    Invite_text: 'Ve a Tonergy y recoge tu TON!',
                    Withdrawal_description: 'Asegúrese de ingresar la dirección de su billetera en la cadena de bloques TON, de lo contrario, perderá sus fondos.',
                    Make_withdrawal: 'Hacer una conclusión',
                    Deposit_description: 'Asegúrese de agregar un comentario al depositar; de lo contrario, los fondos no se acreditarán',
                    Make_deposit: 'Hacer un deposito',
                    Deposit_address: 'Dirección de depósito',
                    Comment: 'Comentario',
                    Find_code: 'Encuentra el código',
                    Find_code_in_chan: 'Encuentra el código en nuestro canal TG',
                    Airdrop_text: 'Su lanzamiento aéreo depende de sus ganancias',
                    Soon: 'Pronto',
                    Rewards: 'Premios',
                    Monthly: 'Mensual',
                    Random_generator: 'Generador aleatorio',

                    Not_enough_tickets: 'No hay suficientes entradas para la Rueda de la Fortuna',
                    Something_went_wrong: 'Algo salió mal'
                }
            },
            ar: {
                translation: {
                    Ton_per_h: 'TON/ة',
                    Balance: 'توازن',
                    deposit: 'إيداع',
                    Generators: 'مولدات',
                    Tasks: 'مهمة',
                    Friends: 'أصدقاء',
                    Top: 'قمة',
                    Payments: 'المدفوعات',
                    Generators_phrase: 'كلما زاد عدد المولدات، زاد الدخل!',
                    generator_1: 'مولد محمول',
                    generator_2: 'مولد ديزل',
                    generator_3: 'لوحة شمسية',
                    generator_4: 'محطة الرياح',
                    generator_5: 'محطة توليد الطاقة الكهربائية الحيوية',
                    generator_6: 'محطة طاقة الفحم',
                    generator_7: 'محطة توليد الكهرباء بالغاز',
                    generator_8: 'محطة الطاقة الكهرومائية',
                    generator_9: 'محطة للطاقة النووية',
                    Connected: 'متصل',
                    connect: 'يتصل',
                    Claim: 'يلتقط',
                    Share: 'للمشاركة',
                    Copy: 'لنسخ',
                    Name: 'اسم',
                    Power: 'قوة',
                    Referrals: 'الإحالات',
                    Your_ton_address: 'لك TON عنوان',
                    Amount: 'رقم',
                    Fee: 'عمولة',
                    Withdraw: 'أخرج',
                    History: 'تاريخ',
                    Status: 'حالة',
                    Date: 'تاريخ',
                    Time_to_next_claim: 'سيكون متاحا عبر',
                    Claim_reward: "يحصل {{amount}} TON",
                    Claim_lucky_ticket: "احصل على عجلة الحظ",
                    Input: 'يدخل',
                    Claim_secret_code_title: 'أدخل الرمز السري للحصول عليه {{amount}} TON',
                    Subscribe_tg_task_title: 'اشترك في القناة ليصلك {{amount}} عجلة الحظ',
                    Place: 'مكان',
                    Profit: 'دخل',
                    List_of_rewards: 'قائمة الجوائز',
                    Lucky_wheel: 'عجلة الحظ',
                    SPIN: 'يدور',
                    How_to_get_lucky_ticket: "للحصول على تذكرة، عليك إكمال مهمة يومية أو دعوة صديق",
                    Jackpot: 'الفوز بالجائزة الكبرى',
                    No_data_yet: 'فارغ',
                    Invite_description: 'قم بدعوة أصدقائك للحصول على تذكرة عجلة الحظ. سوف تحصل أيضًا على 10% من كل عملية شراء يقوم بها صديقك و5% من إحالاته',
                    Invite_text: 'اذهب إلى Tonergy واجمع TONs الخاصة بك!',
                    Withdrawal_description: 'يرجى التأكد من إدخال عنوان محفظة TON (Telegram) الخاصة بك، وإلا فسوف تفقد أموالك',
                    Make_withdrawal: 'طلب الإخراج',
                    Deposit_description: 'يجب عليك تحديد تعليق، وإلا فلن يتم إضافة الأموال',
                    Make_deposit: 'إيداع',
                    Deposit_address: 'عنوان الإيداع',
                    Comment: 'تعليق',
                    Find_code: 'ابحث عن الرمز',
                    Find_code_in_chan: 'ابحث عن الرمز السري في قناة TG الخاصة بنا',
                    Airdrop_text: 'يعتمد الإنزال الجوي الخاص بك على ربحك في الساعة',
                    Soon: 'قريباً',
                    Not_enough_tickets: 'تذاكر عجلة الحظ غير كافية',
                    Something_went_wrong: 'أُووبس حدث خطأ ما'
                }
            }
        },
    });

export default i18n