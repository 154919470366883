import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import moment from "moment/moment";
import api from "../../api";

function TgSubscription({task, reloadTasks}) {
    const [t] = useTranslation();

    const claim = () => {
        api.claimTgSubscription().then((response) => {
            if (response.status === 200) {
                toast.info('Claimed! you have got a reward')
                reloadTasks(moment.now())
            }
        })
    }

    return (
        <div className="row text-center">
            <div className="col-8 p-1">
                {t('Subscribe_tg_task_title', {amount: 3})}
                <br/>
                <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_CHANNEL_LINK}>
                    {process.env.REACT_APP_CHANNEL_LINK}
                </a>
            </div>
            <div className="col-4">
                <button className="btn btn-primary my-3" onClick={claim}>{t('Claim')}</button>
            </div>
        </div>
    )
}

export default TgSubscription