import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import {SDKProvider, initMiniApp} from '@tma.js/sdk-react';
import { initSwipeBehavior } from '@telegram-apps/sdk';
import App from "./App";
import "./i18n"
import api from "./api";


// check version
const version = localStorage.getItem('version')
const resp = await api.version()
if (resp.status === 200) {
    if (version === null || version.toString() !== resp.data.toString()) {
        localStorage.setItem('version', resp.data)
        window.location.reload()
    }
}


if (process.env.REACT_APP_DEV_AUTH === undefined || process.env.REACT_APP_DEV_AUTH === false) {
    const [miniApp] = initMiniApp();
    miniApp.setHeaderColor('#202431');
    miniApp.setBgColor('#202431');
    const [swipeBehavior] = initSwipeBehavior()
    swipeBehavior.disableVerticalSwipe()
}

ReactDOM.createRoot(document.getElementById("root")).render(
    <SDKProvider acceptCustomStyles debug>
        <App/>
    </SDKProvider>
);
