import parachuteIcon from '../../icons/parachute.svg'
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {UserContext} from "../../App";
import { Steps } from 'rsuite';
import './airdrop.css'

function Airdrop() {
    const { t } = useTranslation()
    const {user} = useContext(UserContext)

    const tokenAmount = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }

    return (
        <>
            <div className="row">
                <div className="col-12 text-center" >
                    <div className="text-block p-4">
                        <h5 className="text-center">Airdrop - Tonergy Token</h5>
                        <img className="my-2" src={parachuteIcon} height="50px" alt="Airdrop"/>
                        <h3 ><b className="token-amount">{tokenAmount((user.profit / 120).toFixed(0))}</b> TNRG</h3>
                        <p style={{fontSize: '12px'}}><b>{t('Airdrop_text')}</b></p>
                        <button className="btn" disabled>CLAIM</button>
                    </div>
                    <h3 className="mt-4">Roadmap</h3>
                    <div className="py-3">
                        <Steps theme="dark" current={1} vertical style={{
                            width: '70%',
                            display: 'inline-table',
                            verticalAlign: 'top',
                        }}>
                            <Steps.Item title="Tonergy Launch" description="11 August 2024"/>
                            <Steps.Item title="Tokenomics Design" description="in progress"/>
                            <Steps.Item title="Publish WhitePaper" description="10 September 2024" />
                            <Steps.Item title="Airdrop" description="13 September 2024" />
                            <Steps.Item title="Listing on DEX (dexscreener)" description="23 September 2024" />
                            <Steps.Item title="Listing on CEX (cex.io)" description="30 September 2024" />
                        </Steps>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Airdrop