import {Wheel} from "react-custom-roulette";
import {useContext, useState} from "react";
import WheelArrowImg from "./arrow.png"
import classes from "./LuckyWheel.module.css";
import api from "../../api";
import {toast} from "react-toastify";
import {UserContext} from "../../App";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

function LuckyWheel() {
    const {user, reload} = useContext(UserContext)
    const [mustSpin, setMustSpin] = useState(false)
    const [spinLock, setSpinLock] = useState(false)
    const [prizeNumber, setPrizeNumber] = useState(0)
    const {t} = useTranslation()

    const handleSpinClick = () => {
        if (user.lucky_tickets === 0) {
            toast.warn(t('Not_enough_tickets'))
            return
        }
        if (spinLock) {
            return
        }
        setSpinLock(true)
        api.spin().then((response) => {
            if (response.status === 200) {
                setPrizeNumber(response.data.sector - 1)
                setMustSpin(true)
            }
        })
    }

    const data = [
        {option: '+ 0.0001 TON', style: {backgroundColor: '#3141aa', textColor: 'white'}},
        {option: 'Diesel Generator', style: {backgroundColor: '#2738a3', textColor: 'white'}},
        {option: '+ 0.001 TON', style: {backgroundColor: '#23349e', textColor: 'white'}},
        {option: 'Wind turbines', style: {backgroundColor: '#1a2a97', textColor: 'white'}},
        {option: '+ 0.01 TON', style: {backgroundColor: '#101b67', textColor: 'white'}},
        {option: 'Bio power plant', style: {backgroundColor: '#071045', textColor: 'white'}},
        {option: '1 TON', style: {backgroundColor: '#040a37', textColor: 'white'}},
        {option: 'Gas Power Plant', style: {backgroundColor: '#040a32', textColor: 'white'}},
    ]

    return (
        <div className="container">
            <h5 className="text-center">{t('Lucky_wheel')}</h5>
            <div className="d-flex justify-content-center">
                <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data}
                    radiusLineColor={'rgb(52,56,76)'}
                    innerBorderColor={'rgb(52,56,76)'}
                    outerBorderColor={'rgb(52,56,76)'}
                    pointerProps={{src: WheelArrowImg}}
                    onStopSpinning={() => {
                        setMustSpin(false)
                        setSpinLock(false)
                        reload(moment.now())
                        toast.success(data[prizeNumber].option)
                    }}
                />
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <button className={classes.spinButton} onClick={handleSpinClick}>{t('SPIN')}</button>
                </div>
            </div>

            <h5 className="text-center">{t('List_of_rewards')}</h5>
            <ul>
                <li>+ 0.0001 TON</li>
                <li>{t('generator_2')}</li>
                <li>+ 0.001 TON</li>
                <li>{t('generator_4')}</li>
                <li>+ 0.01 TON</li>
                <li>{t('generator_5')}</li>
                <li>+ 0.1 TON</li>
                <li>{t('generator_7')} ({t('Jackpot')})</li>
            </ul>
            <p>{t('How_to_get_lucky_ticket')}</p>
        </div>
    );
}

export default LuckyWheel;