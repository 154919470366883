import lightningIcon from "../../icons/lightning.svg";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import classes from "./Power.module.css";
import utils from "../../utils/utils";
import {useTranslation} from "react-i18next";
import api from "../../api";
import Tasks from "../Tasks/Tasks";
import wheelIcon from '../../icons/wheel.svg'
import parachuteIcon from '../../icons/parachute.svg'
import LuckyWheel from "../LuckyWheel/LuckyWheel";
import Airdrop from "../Airdrop/Airdrop";

function Power({setContent}) {
    const {t} = useTranslation()
    const [active, setActive] = useState(false)
    const {user} = useContext(UserContext);

    const fetch = () => {
        api.tasks().then((response) => {
            if (response.status === 200) {
                if (
                    response.data.claim_ton.available ||
                    response.data.claim_ticket.available ||
                    response.data.claim_secret.available) {
                    setActive(true)
                }
            }
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
    }, [user])
    return (
        <div className="row justify-content-center position-relative">
            <div
                className={`${classes.circle} ${active ? classes.active : classes.normal} text-center`}
                onClick={() => {
                    setContent({key: null, component: <Tasks/>})
                }}>
                <img height="21" src={lightningIcon} alt="power"/>
                <div className={`${classes.powerPoints} text-truncate`}>
                    +{utils.nanoToTON(user.profit)}
                </div>
                <span>{t('Ton_per_h')}</span>
            </div>
            <div className={classes.airdropBtn} onClick={() => setContent({key: null, component: <Airdrop/>})}>
                <img src={parachuteIcon} alt="Airdrop"/>
                <span>Airdrop</span>
            </div>
            <div onClick={() => setContent({component: <LuckyWheel/>})} className={classes.wheelButton}>
                <img src={wheelIcon} alt="Lucky wheel"/>
                <span>{user.lucky_tickets}</span>
            </div>
        </div>
    )
}

export default Power;