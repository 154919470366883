import classes from "./Generators.module.css";
import diamondIcon from "../../icons/diamond.svg";
import randomIcon from "./images/random.svg";
import {useTranslation} from "react-i18next";
import api from "../../api";
import {toast} from "react-toastify";
import moment from "moment";
import {useContext} from "react";
import {UserContext} from "../../App";

function RandomGenerator({fetchGenerators}) {
    const {t} = useTranslation()
    const {reload} = useContext(UserContext);

    const handleConnect = () => {
        api.randomConnect().then((response) => {
            if (response.status === 200) {
                toast.success(t('Connected') + ': ' + t('generator_' + response.data))
                reload(moment.now())
                fetchGenerators()
            }
        })
    }

    return (
        <div className="col-6">
            <div className={`${classes.product} ${classes.randomGenerator}`}>
                <img height="80" width="80" src={randomIcon}/>
                <p className={classes.productTitle}>
                    {t('Random_generator')}
                </p>
                <div>+<strong>?</strong> {t('Ton_per_h')}</div>
                <div>{t('Monthly')}: <strong>?</strong> TON</div>
                <div className="py-2">
                    ...
                </div>
                <button className="btn" onClick={handleConnect}>
                    <img src={diamondIcon} className={classes.connectImg} alt="price"/>
                    1 TON
                </button>
            </div>
        </div>
    )
}

export default RandomGenerator