import axios from "axios";
import {retrieveLaunchParams} from "@tma.js/sdk-react";
import {toast} from "react-toastify";

let authData = 'test';
if (process.env.REACT_APP_DEV_AUTH === 'false') {
    const { initDataRaw } = retrieveLaunchParams();
    authData = initDataRaw
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
api.defaults.headers.common['Authorization'] = authData;
api.interceptors.response.use((response) => response, (error) => {
    if (error.response !== undefined && error.response.data.message !== undefined) {
        toast.warn(error.response.data.message)
    } else {
        toast.error('Something went wrong')
    }
    return error
});

const methods = {
    profile() {
        return api.get('/profile')
    },
    deposits(page) {
        return api.get('/deposits', {params: {page}})
    },
    withdrawals(page) {
        return api.get('/withdrawals', {params: {page}})
    },
    referrals(page) {
        return api.get('/referrals', {params: {page}})
    },
    tasks() {
        return api.get('/tasks')
    },
    claim() {
        return api.post('/claim')
    },
    claimTicket() {
        return api.post('/claim/ticket')
    },
    claimSecret(code) {
        return api.post('/claim/secret', {code})
    },
    claimTgSubscription() {
        return api.post('/claim/subscription')
    },
    withdraw(amount, address) {
        return api.post('/withdraw', {amount, address})
    },
    top() {
        return api.get('/top')
    },
    generators() {
        return api.get('/generators')
    },
    connectGenerator(generatorID) {
        return api.post('/generator', {id: generatorID})
    },
    changeUserLang(lang) {
        return api.post('/user/lang', {lang})
    },
    spin() {
        return api.post('/spin')
    },
    referralStats() {
        return api.get('/referral/stats')
    },
    randomConnect() {
        return api.post('/random/generator')
    },
    version() {
        return api.get('/version')
    }
}

export default methods