import {useContext} from "react";
import {toast} from "react-toastify";
import {UserContext} from "../../App";
import Timer from "../Timer";
import moment from "moment";
import {useTranslation} from "react-i18next";

function Claimer({task, reloadTasks, claimer, title}) {
    const {t} = useTranslation();
    const {reload} = useContext(UserContext);

    const claim = () => {
        claimer().then((response) => {
            if (response.status === 200) {
                toast.success('Claimed! you have got a reward')
                reloadTasks(moment.now())
                reload(moment.now())
            }
        })
    }

    return (
        <div className="row text-center">
            <div className="col-8">
                {task.available && title}
                {!task.available &&
                    <>
                        <strong>{title} </strong>
                        <br/>
                        {t('Time_to_next_claim')}: <Timer timeout={task.available_at} handleTimeout={reloadTasks}/>
                    </>
                }
            </div>
            <div className="col-4">
                {task.available && <button className="btn btn-success" onClick={claim}>{t('Claim')}</button>}
                {!task.available && <button className="btn" disabled>{t('Claim')}</button>}
            </div>
        </div>
    )
}

export default Claimer