import {useContext} from "react";
import {UserContext} from "../../App";
import defaultUserIcon from '../../icons/user-default.svg'
import classes from "./Header.module.css";
import LangSelector from "./LangSelector";

function Header() {
    const {user} = useContext(UserContext)
    return (
        <header className={classes.header}>
            <div className="row">
                <div className="col-9 text-truncate">
                    <img height="25" width="25"
                         src={user.photo_url === '' ? defaultUserIcon : user.photo_url}
                         alt={user.name}/>
                    {user.name}
                </div>
                <div className="col-3 text-end">
                    <div className="px-2">
                        <LangSelector/>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;