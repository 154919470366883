import {CopyToClipboard} from "react-copy-to-clipboard";
import {useContext} from "react";
import {UserContext} from "../../App";
import api from "../../api";
import PageableTable from "../PagebleTable/PageableTable";
import {toast} from "react-toastify";
import defaultUserIcon from "../../icons/user-default.svg";
import {useTranslation} from "react-i18next";
import utils from "../../utils/utils";
import ReferralStats from "./ReferralStats";

function Referrals() {
    const {user} = useContext(UserContext)
    const {t} = useTranslation()

    const fetcher = (page) => {
        return api.referrals(page).then((response) => {
            if (response.status === 200) {
                const data = response.data.list.map((fields) => {
                    return {
                        user: (
                            <p className="text-truncate" style={{maxWidth: '120px'}}>
                                <img height="20" className="mx-2"
                                     src={fields.photo_url === '' ? defaultUserIcon : fields.photo_url}
                                     alt={fields.name}/>
                                {fields.name}
                            </p>
                        ),
                        profit: utils.nanoToTON(fields.profit),
                        referrals: fields.referrals,
                    }
                })
                return {data, total: response.data.total_l1}
            }
        })
    }
    const personalLink = 'https://t.me/' + process.env.REACT_APP_BOT_USERNAME + '?start=' + user.id
    const shareTxt = t('Invite_text')
    return (
        <div className="container">
            <div className="text-block text-center">
                <p>{t('Invite_description')}</p>
                <p><strong>{personalLink}</strong></p>
                <div className="row py-3">
                    <div className="col-6">
                        <a href={"https://telegram.me/share/url?url=" + personalLink + "&text=" + encodeURIComponent(shareTxt)}>
                            <button className="btn btn-outline-primary">
                                {t('Share')}
                            </button>
                        </a>
                    </div>
                    <div className="col-6">
                        <CopyToClipboard text={personalLink} onCopy={() => toast('Copied')}>
                            <button className="btn btn-outline-primary">{t('Copy')}</button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
            <div className="row">
                <ReferralStats/>
                <PageableTable fetcher={fetcher} columns={[t('Name'), t('Profit'), t('Referrals')]}/>
            </div>
        </div>
    );
}

export default Referrals;