import {createContext, useEffect, useState} from "react";
import api from "./api";
import Header from "./components/Header/Header";
import Power from "./components/Power/Power";
import Nav from "./components/Nav/Nav";
import Deposits from "./components/Deposits/Deposits";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import updateIcon from "./icons/refresh.svg";
import moment from "moment";
import Loader from "./components/Loader";
import Generators from "./components/Generators/Generators";
import Balance from "./components/Balance";
import {useTranslation} from "react-i18next";
import {TonConnectUIProvider} from "@tonconnect/ui-react";

export const UserContext = createContext(null)

function App() {
    const {t, i18n} = useTranslation();
    const [reloader, reload] = useState(moment.now)
    const [content, setContent] = useState({
        key: 'shop',
        component: <Generators/>
    })
    const [user, setUser] = useState(null)
    useEffect(() => {
        const fetch = () => {
            api.profile().then((response) => {
                if (response.status === 200) {
                    setUser(response.data)
                    if (i18n.language !== response.data.lang) {
                        i18n.changeLanguage(response.data.lang)
                    }
                }
            })
        }
        fetch()
    }, [reloader])

    if (user === null) {
        return (
            <div className="app">
                <div className="container">
                    <p className="py-3"></p>
                    <Loader/>
                </div>
            </div>
        )
    }


    return (
        <div className="app">
            <div className="container">
                <UserContext.Provider value={{user, setUser, reload}}>
                    <TonConnectUIProvider manifestUrl="https://tonergy.click/tonconnect-manifest.json">
                        <Header/>
                        <Power setContent={setContent}/>
                        <div className="text-center py-3">
                        <span onClick={() => setContent({key: null, component: <Deposits/>})}>
                            {t('Balance')}: <span><strong><Balance/></strong></span> TON
                            <span className="top-up-btn">+</span>
                        </span>
                            <span onClick={() => {
                                setUser(null)
                                reload(moment.now())
                            }} style={{paddingLeft: '20px'}}>
                            <img height="16" src={updateIcon} alt="update balance"/>
                    </span>
                        </div>

                        <Nav content={content} setter={setContent}/>
                        <div className="row">
                            <div className="col-12 mt-3">
                                {content.component}
                            </div>
                        </div>
                    </TonConnectUIProvider>
                </UserContext.Provider>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </div>
        </div>
    );
}

export default App;
