import moment from "moment/moment";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../App";
import utils from "../utils/utils";

function Balance () {
    const {user} = useContext(UserContext)
    const [balance, setBalance] = useState(user.total_balance)
    useEffect(()=> {}, [user])

    useEffect(() => {
        const interval = setInterval(() => {
            let balance = user.balance
            let now = moment.utc().unix()
            let duration = now - user.last_sync
            let profitPerSec = user.profit / (60 * 60)
            let income = (profitPerSec * duration).toFixed(0)
            setBalance(balance + Number(income))
        }, 1000);
        return () => clearInterval(interval);
    }, [user])

    return (
        <>{utils.nanoToTON(balance)}</>
    )
}

export default Balance