import {useEffect, useState} from "react";
import moment from "moment";


function Timer({timeout, handleTimeout}) {
    const initDuration =  moment.unix(timeout).diff(moment.utc())
    const [timeLeft, setTimeLeft] = useState(initDuration)
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(moment.unix(timeout).diff(moment.utc()))
        }, 1000);
        return () => clearInterval(interval);
    }, [timeout])
    useEffect(() => {
        if (timeLeft <= 0) {
            handleTimeout()
        }
    }, [timeLeft, handleTimeout])
    const days = moment.duration(moment.unix(timeout).diff(moment.utc())).days()
    if (days > 0) {
        return days + 'd ' + moment.utc(timeLeft).format("HH:mm:ss")
    }
    return moment.utc(timeLeft).format("HH:mm:ss")

}

export default Timer