import api from "../../api";
import {useEffect, useState} from "react";
import Loader from "../Loader";
import utils from "../../utils/utils";
import {useTranslation} from "react-i18next";

function ReferralStats() {
    const [stats, setStats] = useState(null)
    const {t} = useTranslation()

    const fetch = () => {
        api.referralStats().then((response) => {
            if (response.status === 200) {
                setStats(response.data)
            }
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    if (stats === null) {
        return <Loader/>
    }

    return (
        <div className="row">
            <div className="row-12 py-3">
                <span>
                {t('Rewards')}: <b>{utils.nanoToTON(stats.rewards)} TON</b>
            </span>
                <span className="float-end">
                {t('Friends')}: <b>{stats.count}</b>
            </span>
            </div>
        </div>
    )
}

export default ReferralStats