import classes from "./Header.module.css";
import {useTranslation} from "react-i18next";
import api from "../../api";
import {useContext, useState} from "react";
import {UserContext} from "../../App";

function LangSelector() {
    const {user} = useContext(UserContext);
    const [lang, setLang] = useState(user.lang)
    const {i18n} = useTranslation()

    const selectEvent = e => {
        i18n.changeLanguage(e.target.value)
        api.changeUserLang(e.target.value)
        setLang(e.target.value)
    }

    return (
        <select value={lang} name="lang" className={`formSelector ${classes.langSelector} btn-dark`} data-bs-theme="dark" onChange={selectEvent}>
            <option value="en">EN</option>
            <option value="ru">RU</option>
            <option value="es">ES</option>
        </select>
    )
}

export default LangSelector