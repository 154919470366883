import classes from "./InputNumber.module.css";

function InputNumber({value, setValue}) {
    const onChangeHandler = (e) => {
        if (e.target.value === '') {
            setValue('')
            return
        }
        if (isNaN(e.target.value)) {
            e.preventDefault()
            return
        }
        setValue(e.target.value)
    }
    const add = () => {
        setValue(parseInt(value) + 1)
    }

    const sub = () => {
        if (value <= 0.1) {
            return
        }
        if (value <= 1) {
            setValue((value-0.1).toFixed(2))
            return
        }
        setValue((parseInt(value)-1).toFixed(2))
    }

    return (
        <div className="input-group align-content-center justify-content-center">
            <input type="button" value="-" className="button-minus" data-field="quantity" onClick={sub} />
            <input value={value} name="quantity" onChange={onChangeHandler} className={classes.amountInput}/>
            <input type="button" value="+" className="button-plus" data-field="quantity" onClick={add} />
        </div>
    )
}

export default InputNumber