import {MutatingDots} from "react-loader-spinner";

function Loader() {
    return (
        <>
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#506895"
                secondaryColor="#506895"
                radius="15"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{margin: 'auto', display: 'block', textAlign: 'center'}}
                wrapperClass=""
            />
            <p className="text-center">Loading ...</p>
        </>
    )
}

export default Loader