import {useContext, useState} from "react";
import {toast} from "react-toastify";
import {UserContext} from "../../App";
import Timer from "../Timer";
import moment from "moment";
import {useTranslation} from "react-i18next";
import api from "../../api";
import OtpInput from 'react-otp-input';
import classes from "./Tasks.module.css";

function SecretCode({task, reloadTasks}) {
    const [t] = useTranslation();
    const {reload} = useContext(UserContext);
    const [secretCode, setSecretCode] = useState('');

    const handleChange = (value) => {
        setSecretCode(value)
        if (value.length === 4) {
            api.claimSecret(Number(value)).then((response) => {
                if (response.status === 200) {
                    toast.success('Claimed! you have got a reward')
                    reloadTasks(moment.now())
                    reload(moment.now())
                }
            })
            setSecretCode('')
        }
    }

    return (
        <div className="row text-center">
            <div className="col-12">
                {task.available &&
                    <>
                        <p className="px-4">{t('Claim_secret_code_title', {amount: 0.01})}</p>
                        <div className="d-flex justify-content-center">
                            <OtpInput
                                value={secretCode}
                                onChange={handleChange}
                                numInputs={4}
                                renderSeparator={<span>-</span>}
                                inputStyle={classes.otpInput}
                                renderInput={(props) => <input {...props} />}
                                inputType="number"
                            />
                        </div>
                        <div className="p-3">{t('Find_code_in_chan')}</div>
                        <a href={process.env.REACT_APP_CHANNEL_LINK}>
                            <button className="btn btn-outline-primary">{t('Find_code')} 🔍</button>
                        </a>

                    </>
                }
                {!task.available &&
                    <>
                        <strong>{t('Claim_secret_code_title', {amount: 0.01})}</strong>
                        <br/>
                        {t('Time_to_next_claim')}: <Timer timeout={task.available_at} handleTimeout={reloadTasks}/>
                    </>
                }
            </div>
        </div>
    )
}

export default SecretCode