import friendsIcon from '../../icons/friends.svg'
import paymentsIcon from '../../icons/withdrawals.svg'
import tasksIcon from '../../icons/tasks.svg'
import topIcon from '../../icons/top.svg'
import shopIcon from '../../icons/shop.svg'
import classes from "./Nav.module.css";
import Generators from "../Generators/Generators.jsx";
import Referrals from "../Referrals/Referrals";
import Tasks from "../Tasks/Tasks";
import Withdrawals from "../Withdrawals";
import Top from "../Top";
import {useTranslation} from "react-i18next";

function NavItem({value, onClick, active}) {
    return (
        <div className={`${classes.navItem} ${active ? classes.active : ''}`} onClick={onClick}>
            <img height="25" src={value.icon} alt={value.title}/>
            <div>{value.title}</div>
        </div>
    )
}

function Nav({content, setter}) {
    const { t } = useTranslation();

    const tabs = [
        {key: 'generators', title: t('Generators'), icon: shopIcon, component: <Generators/>},
        {key: 'tasks', title: t('Tasks'), icon: tasksIcon, component: <Tasks/>},
        {key: 'friends', title: t('Friends'), icon: friendsIcon, component: <Referrals/>},
        {key: 'top', title: t('Top'), icon: topIcon, component: <Top/>},
        {key: 'payments', title: t('Payments'), icon: paymentsIcon, component: <Withdrawals/>},
    ]
    return (
        <div className="container">
            <div className="row">
                <div className={classes.nav}>
                    <div className="d-flex align-items-center justify-content-center">
                        {
                            tabs.map((t) => {
                                return <NavItem active={t.key === content.key} key={t.key} value={t}
                                                onClick={() => setter({
                                                    key: t.key,
                                                    component: t.component
                                                })}/>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nav