import Claimer from "./Claimer";
import api from "../../api";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Loader from "../Loader";
import SecretCode from "./SecretCode";
import TgSubscription from "./TgSubscription";

function Tasks() {
    const {t} = useTranslation()
    const [tasks, setTasks] = useState(null)

    const fetchTasks = () => {
        api.tasks().then((response) => {
            if (response.status === 200) {
                setTasks(response.data)
            }
        })
    }

    useEffect(() => {
        fetchTasks()
    }, [])

    if (tasks == null) {
        return <Loader/>
    }

    return (
        <div className="container">
            <div className="text-block p-3">
                <Claimer
                    task={tasks.claim_ton}
                    reloadTasks={fetchTasks}
                    claimer={api.claim}
                    title={t('Claim_reward', {amount: 0.001})}
                />
            </div>
            <div className="text-block p-3">
                <Claimer
                    task={tasks.claim_ticket}
                    reloadTasks={fetchTasks}
                    claimer={api.claimTicket}
                    title={t('Claim_lucky_ticket')}
                />
            </div>
            <div className="text-block p-3">
                <SecretCode
                    task={tasks.claim_secret}
                    reloadTasks={fetchTasks}
                />
            </div>
            {
                tasks.tg_subscription.available &&
                <div className="text-block p-3">
                    <TgSubscription reloadTasks={fetchTasks}/>
                </div>
            }
            <div style={{height: '200px'}}>
            </div>
        </div>
    );
}

export default Tasks;